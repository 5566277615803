import './App.css';
import styled from 'styled-components'
import { useState, useEffect } from 'react';
import { Parser } from 'expr-eval';

const Button = styled.button`
  width: 100%;
  height: 64px;
  font-size: 22px;
  text-align: center;
  border-radius: 8px;
  margin: 2px 0px 0px 0px;
`;

const ButtonNum = styled(Button)`
  background-color: #f0f0f0;
`;

const ButtonOp = styled(Button)`
  background-color: #fe9a2e;
  color: #fff;
`;

const ButtonC = styled(Button)`
  background-color: #ff4400;
  color: #fff;
`;

const ButtonCell = styled.td`
`;

const ButtonBox = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const Title = styled.div`
  font-size: 32px;
  text-align: center;
  margin-bottom: 16px;
`;

const AppContainer = styled.div`
  color: #212529;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const ResultBox = styled.div`
  width: 100%;
  height: 36px;
  font-size: 28px;
  text-align: center;
  margin-bottom: 8px;
`;

const Container = styled.div`
  padding: 10px;
`;

function toFormatOp(x) {
  if (x === '*') {
    return '×';
  }
  if (x === '+') {
    return '+';
  }
  if (x === '/') {
    return '÷';
  }
  if (x === '-') {
    return '-';
  }
}

function App() {
  const [value, setValue] = useState([]);

  const [computedResult, setComputedResult] = useState('');

  const readyToCompute = value.length !== 0 && value[value.length - 1] >= '0' && value[value.length - 1] <= '9';

  const numberDisabled = readyToCompute || (value.length === 0 || value[value.length - 1] !== '=');
  const equalDisabled = readyToCompute || !numberDisabled || value.length === 0;
  const clearDisabled = value.length === 0;
  const opDisabled = readyToCompute || !numberDisabled;

  useEffect(() => {
    if (readyToCompute) {
      // compute here
      const ops = value.filter(x => x === '+' || x === '-' || x === '/' || x === '*');
      const target = value.filter(x => x >= '0' && x <= '9')[0] - '0';

      const state = [];
      const used = {};

      const options = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      function dfs() {
        if (state.length === ops.length + 1) {
          let s = "";
          for (let i = 0; i < state.length; i++) {
            s += state[i];
            if (i < ops.length) {
              s += ops[i];
            }
          }
          if (Parser.evaluate(s) === target) {
            let formatS = "";
            for (let i = 0; i < state.length; i++) {
              formatS += state[i];
              if (i < ops.length) {
                formatS += toFormatOp(ops[i]);
              }
            }
            return formatS + '=' + target;
          } else {
            return null;
          }
        } else {
          for (const x of options) {
            if (!used[x]) {
              used[x] = true;
              state.push(x);

              const res = dfs();
              if (res) {
                return res;
              }

              state.pop();
              used[x] = false;
            }
          }
        }
        return null;
      }
      const result = dfs();

      setComputedResult(result || '👲＜どこかバグってるあるね');
    }
  }, [value, readyToCompute]);

  let formatResult = computedResult;
  if (!formatResult) {
    formatResult = "□";
    for (const x of value) {
      if (x === '=') {
        formatResult += '=';
      } else if (x >= '0' && x <= '9') {
        formatResult += x;
      } else {
        formatResult += toFormatOp(x) + '□';
      }
    }
  }

  return (
    <AppContainer>
      <Title>太閤立志伝V 算術計算機</Title>
      <Container>
        <ResultBox>{formatResult}</ResultBox>
        <ButtonBox>
          <tbody>
            <tr>
              <ButtonCell>
                <ButtonNum disabled={numberDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '7']
                  });
                }}>7</ButtonNum>
              </ButtonCell>
              <ButtonCell>
                <ButtonNum disabled={numberDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '8']
                  });
                }}>8</ButtonNum>
              </ButtonCell>
              <ButtonCell>
                <ButtonNum disabled={numberDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '9']
                  });
                }}>9</ButtonNum>
              </ButtonCell>
              <ButtonCell>
                <ButtonOp disabled={opDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '/']
                  });
                }}
                >÷</ButtonOp>
              </ButtonCell>
            </tr>
            <tr>
              <ButtonCell>
                <ButtonNum disabled={numberDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '4']
                  });
                }}>4</ButtonNum>
              </ButtonCell>
              <ButtonCell>
                <ButtonNum disabled={numberDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '5']
                  });
                }}>5</ButtonNum>
              </ButtonCell>
              <ButtonCell>
                <ButtonNum disabled={numberDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '6']
                  });
                }}>6</ButtonNum>
              </ButtonCell>
              <ButtonCell>
                <ButtonOp disabled={opDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '*']
                  });
                }}
                >×</ButtonOp>
              </ButtonCell>
            </tr>
            <tr>
              <ButtonCell>
                <ButtonNum disabled={numberDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '1']
                  });
                }}>1</ButtonNum>
              </ButtonCell>
              <ButtonCell>
                <ButtonNum disabled={numberDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '2']
                  });
                }}>2</ButtonNum>
              </ButtonCell>
              <ButtonCell>
                <ButtonNum disabled={numberDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '3']
                  });
                }}>3</ButtonNum>
              </ButtonCell>
              <ButtonCell>
                <ButtonOp disabled={opDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '-']
                  });
                }}
                >-</ButtonOp>
              </ButtonCell>
            </tr>
            <tr>
              <ButtonCell>
                <ButtonNum disabled={numberDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '0']
                  });
                }}>0</ButtonNum>
              </ButtonCell>
              <ButtonCell>
                <ButtonC disabled={clearDisabled} onClick={() => {
                  setValue([]);
                  setComputedResult('');
                }}
                >C</ButtonC>
              </ButtonCell>
              <ButtonCell>
                <ButtonOp disabled={equalDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '=']
                  });
                }}>=</ButtonOp>
              </ButtonCell>
              <ButtonCell>
                <ButtonOp disabled={opDisabled} onClick={() => {
                  setValue(prevValue => {
                    return [...prevValue, '+']
                  });
                }}
                >+</ButtonOp>
              </ButtonCell>
            </tr>
          </tbody>
        </ButtonBox>
      </Container>
    </AppContainer>
  );
}

export default App;
